import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

import { ArtPostCard, Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="container">
                    <h1 className="home-featured">Recent art</h1>
                    <section>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 2}}>
                        <Masonry>
                            {posts.map(({ node }) => {
                                for (let i = 0; i < node.tags.length; i++) {
                                    // Check if the node has art as one of its tags & include it
                                    let tag = node.tags[i];
                                    if ( node.featured && tag.slug === "art" ) {
                                        return (<div><ArtPostCard key={node.id} post={node} hideFeatured="true" /></div>);
                                    } else {
                                        return null;
                                    }
                                }
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
                <div className="container">
                    <h1 className="home-featured">Featured blogs</h1>
                    <section className="post-feed featured">
                    {posts.map(({ node }) => {
                        for (let i = 0; i < node.tags.length; i++) {
                            // Check if the node has art as one of its tags & exclude it
                            let tag = node.tags[i];
                            if (tag.slug === "art" ) {
                                return null;
                            } else {
                                return (node.featured && <div><PostCard key={node.id} post={node} hideFeatured="true" /></div>);
                            }
                        }
                        })}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>

        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!, $skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
